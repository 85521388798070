*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: #dadada;
  font-family: Arial, Helvetica, sans-serif;
}

img {
  max-width: 200px;
}

nav {
  background-color: #ffffff;
  padding: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* nav img {
  margin-left: 6.8rem;
} */

nav img {
  margin-top: 1rem;
}

main {
  width: 80%;
  padding: 0.6rem;
  margin: 0 auto;
  margin-top: 20px;
}

label,
input,
select,
option {
  display: block;
  margin-bottom: 1.2rem;
}

section {
  background-color: #ffffff;
  margin: 0.2rem;
  margin-top: 0.6rem;
  padding: 0.3rem;

  box-shadow: -4px -4px 10px -6px black;
}

form {
  margin: 1rem;
}

h2,
p {
  text-align: center;
}

select {
  margin-top: 0.4rem;
}

label {
  font-size: 1rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

input,
select,
option,
button {
  width: 98%;
  height: 25px;
}

input {
  padding: 0.3rem;
}

h2 {
  margin-top: 3rem;
  font-size: 2rem;
}

p {
  font-size: 1.1rem;
  margin-top: -0.5rem;
}

.line {
  width: 90%;
  height: 1px;
  margin-left: 20px;
  margin-top: 35px;
  margin-bottom: 30px;
  background-color: #dadada;
}

.submit {
  background-color: #fe6a48;
  color: #ffffff;
  border: none;
  font-size: 1.5rem;
  text-align: center;
  padding: 0.5rem;
  height: initial;
  font-weight: bold;
}

.report {
  width: auto;
  margin-left: 170px;
  margin-top: 20px;
  border: none;
  background-color: #8c8c8c;
  color: white;
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
}

input::placeholder {
  color: #dadada;
}

@media (min-width: 740px) {
  main {
    width: 50%;
  }
}

@media (min-width: 940px) {
  main {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  main {
    width: 30.2%;
  }
}

/* .whole {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10%;
}

.flex1 {
  grid-column: 1/2;
} */

/* .whole {
  display: grid;
  grid-template-columns: repeat(12, auto);
  gap: 10%;
}

.flex1 {
  grid-column: 1/10;
}

.flex2 {
  grid-column: 11/13;
} */

/* .whole {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  --columns: 100;
  gap: 10%;
}

.flex1 {
  --width: 90;
  --gap-constant: 0.3%;
  --initialbasis: calc(var(--width, 0) / var(--columns, 16) * 100%);
  --gap: calc(
    (var(--columns, 16) - var(--width, 0)) * var(--gap-constant, 0.3%)
  );
  flex-basis: calc(var(--initialbasis) - var(--gap));
}

.flex2 {
  --width: 10;
  --gap-constant: 0.3%;
  --initialbasis: calc(var(--width, 0) / var(--columns, 16) * 100%);
  --gap: calc(
    (var(--columns, 16) - var(--width, 0)) * var(--gap-constant, 0.3%)
  );
  flex-basis: calc(var(--initialbasis) - var(--gap));
} */
