* {
  box-sizing: border-box;
}

body {
  background-color: rgb(255, 255, 255);
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.whole {
  color: rgb(33, 43, 54);
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  font-size: 16px;

  flex-basis: 76%;
}

.part {
  display: grid;
  font-weight: 500;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  grid-template-columns: 324px 324px;
}

.flex1 {
  margin-top: -20px;
}

.flex2 {
  margin-top: -20px;
  margin-left: 10px;
}

input,
select {
  display: block;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(33, 43, 54);
  border-width: 2px;
  border-radius: 8px;
  border: 2px solid #dce0e4;
  height: 50px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-top: 30px;
  width: 296px;
}

select {
  color: #212b36;
  display: block;
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0);
  width: 296px;
  height: 50px;
  padding: 8px;
  margin-left: 10px;
  margin-top: 31.4px;
}

input::placeholder {
  color: #919eab;
}

.sub {
  background-color: rgb(0, 171, 85);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 900;
  outline-color: rgb(255, 255, 255);
  max-width: 150px;
  height: 40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 156px;
}

.sub:hover {
  background-color: rgb(0, 123, 85);
}

.mobilecode {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.mobilecode select {
  max-width: 90px;
  font-size: 12px;
}

.mobilecode input {
  max-width: 193px;
  margin-right: 10px;
}

input {
  outline: none;
}

select {
  outline: none;
}

input:focus {
  border-color: #212b36;
  border-width: 1px;
}

select:focus {
  border-color: #212b36;
  border-width: 1px;
}

.error-msg {
  color: rgb(255, 86, 48);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: -20px;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 8px;
  text-align: left;
}

@media (max-width: 600px) {
  .whole {
    flex-basis: 100%;
  }
  .part {
    display: flex;
    flex-basis: 100%;
    flex-flow: column wrap;
  }

  .flex2 {
    margin-left: 0px;
  }

  .mobilecode input {
    margin-right: 0;
  }

  .lastname {
    position: relative;
    top: -230px;
  }

  .email {
    position: relative;
    top: 70px;
  }

  .mobilecode {
    position: relative;
    top: -160px;
  }

  .country {
    position: relative;
    top: 140px;
  }

  .university {
    position: relative;
    top: 140px;
  }

  .program {
    margin-top: 20px;
  }
}
